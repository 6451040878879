import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://d880d0fb97f04ebba1e36265b46dc38f@o617827.ingest.sentry.io/5808847",
  release: "234a58167797d8acbdb3de54433c18a17e01a951",
  environment: "staging",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
});
